@import "./styles/mat-select_style.scss";

/* #intercom-container,
.intercom-lightweight-app {
  display: none !important;
} */

:root {
  --main-theme-color: #5469d4;
  --secondary-theme-color: #6161ff;
  --accent-theme-color: #000000de;
  --text-main-color: #000;
  --subtitle-main-color: #383838;
  --sidenav-theme-color: #f6f8fa;
  --component-container-theme-color: #fff;
  --scroll-webkit: #ffffff;
  --border-color-theme: #dee3ea;
  --border-color-theme-accent2: #f7f8fa;
  --navmenu-item-container: #fff;
  --navmenu-icon-svg-color: #7a8093;
  --topnav-theme-color: #f0f2f7;
  --filter-bg-theme-color: #fff;
  --accent-theme-color2: #5a6bd3;
  --zoom-val: 0.75;
  --mdc-checkbox-selected-icon-color: #6161ff;
  --mdc-checkbox-selected-hover-icon-color: #6d6de7;
  --mdc-checkbox-selected-focus-icon-color: #6161ff;
  --mdc-checkbox-selected-pressed-icon-color: #6161ff;
  color-scheme: light;
}

.dark-theme {
  --main-theme-color: #fff;
  --secondary-theme-color: #6161ff;
  --accent-theme-color: #afb8c7;
  --text-main-color: #fff;
  --subtitle-main-color: #fff;
  --sidenav-theme-color: #1b1b1b;
  --component-container-theme-color: #252525;
  --scroll-webkit: #252525;
  --border-color-theme: #dee3ea24;
  --border-color-theme-accent2: #e0e0e014;
  --navmenu-item-container: #282828;
  --navmenu-icon-svg-color: #7a8093;
  --topnav-theme-color: #3a3a3a;
  --accent-theme-color2: #94b2ff;
  --filter-bg-theme-color: #3a3a3a;
  --mdc-checkbox-selected-icon-color: #6161ff;
  --mdc-checkbox-selected-hover-icon-color: #6d6de7;
  --mdc-checkbox-selected-focus-icon-color: #6161ff;
  --mdc-checkbox-selected-pressed-icon-color: #6161ff;
  color-scheme: dark;
}
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
@media only screen and (max-width: 1450px) {
  *::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
}
*::-webkit-scrollbar-track {
  background: var(--scroll-webkit);
}
*::-webkit-scrollbar-thumb {
  background: #888;
}
::ng-deep *::-webkit-scrollbar-thumb:hover {
  background: #555;
}
body {
  background-color: var(--component-container-theme-color) !important;
  margin: 0px;
}
.organic_loading {
  opacity: 0;
}
.mat-mdc-paginator {
  background: transparent !important;
}
.mat-mdc-paginator-range-label {
  white-space: nowrap;
}
.mat-mdc-paginator-container {
  font-size: 12px;
}
.dark-theme .mat-mdc-paginator-container {
  color: #fff;
}
.dark-theme .mat-mdc-paginator-container .mat-mdc-paginator-icon {
  fill: #fff;
}
.dark-theme .google-visualization-tooltip {
  background: #3a3a3a !important;
  color: #fff !important;
  border-color: #1e1e1e !important;
  box-shadow: none !important;
}
div.mat-mdc-autocomplete-panel {
  padding: 0 !important;
}

.mat-mdc-card {
  background: var(--component-container-theme-color) !important;
}
.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.mat-mdc-slide-toggle .mat-mdc-slide-toggle-ripple,
.mat-mdc-slide-toggle .mdc-switch__ripple::after,
.mdc-switch.mdc-switch--unselected:enabled:active .mdc-switch__handle::after,
.mdc-switch__icon {
  display: none;
}
.mat-mdc-dialog-container .mdc-dialog__title,
.mat-mdc-dialog-container .mdc-dialog__content {
  color: #000 !important;
}
.dark-theme .mat-mdc-dialog-container .mdc-dialog__title,
.dark-theme .mat-mdc-dialog-container .mdc-dialog__content {
  color: #fff !important;
}
.dark-theme .custom_table .custom_table_item-sticky {
  background-color: #252525 !important;
}
.dark-theme .custom_table thead .custom_table_item-sticky {
  background-color: #3a3a3a !important;
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #5469d4 !important;
  --mdc-switch-selected-handle-color: #5469d4 !important;
  --mdc-switch-selected-hover-state-layer-color: #5469d4 !important;
  --mdc-switch-selected-pressed-state-layer-color: #5469d4 !important;
  --mdc-switch-selected-focus-handle-color: #6161ff !important;
  --mdc-switch-selected-hover-handle-color: #6161ff !important;
  --mdc-switch-selected-pressed-handle-color: #6161ff !important;
  --mdc-switch-selected-focus-track-color: #6363b9 !important;
  --mdc-switch-selected-hover-track-color: #6363b9 !important;
  --mdc-switch-selected-pressed-track-color: #6363b9 !important;
  --mdc-switch-selected-track-color: #6363b9 !important;
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  background-color: var(--main-theme-color) !important;
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element {
  background-color: var(--main-theme-color) !important;
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000 !important;
  --mdc-radio-disabled-unselected-icon-color: #000 !important;
  --mdc-radio-unselected-hover-icon-color: #212121 !important;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54) !important;
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54) !important;
  --mdc-radio-selected-focus-icon-color: var(--main-theme-color) !important;
  --mdc-radio-selected-hover-icon-color: var(--main-theme-color) !important;
  --mdc-radio-selected-icon-color: var(--main-theme-color) !important;
  --mdc-radio-selected-pressed-icon-color: var(--main-theme-color) !important;
  --mat-radio-ripple-color: #000 !important;
  --mat-radio-checked-ripple-color: var(--main-theme-color) !important;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38) !important;
}

.mat-mdc-radio-button.mat-mdc-radio-checked
  .mat-ripple-element
  .mat-mdc-radio-button.mat-accent
  .mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled
  + .mdc-radio__background
  .mdc-radio__inner-circle,
.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
  background-color: var(--main-theme-color) !important;
}
.mdc-switch.mdc-switch--unselected:enabled:focus:not(:active)
  .mdc-switch__handle::after {
  background: #fff !important;
}
.mat-mdc-tooltip .mdc-tooltip__surface {
  font-size: 10px !important;
}
.dark-theme .mat-card {
  box-shadow: unset !important;
}
.dark-theme .mat-tab-label.mat-tab-disabled,
.dark-theme .mat-tab-link.mat-tab-disabled {
  color: rgb(255 255 255 / 38%) !important;
}

* {
  font-family: Roboto, sans-serif;
}

.dark-theme .dashboard-header-intro-inner p {
  color: #ffffff91 !important;
}

.mat-tab-header,
.mat-tab-nav-bar {
  border-color: var(--border-color-theme);
}

.mat-mdc-tab .mdc-tab__text-label {
  color: var(--accent-theme-color) !important;
}
p.chart-item-title_category {
  color: #000;
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--main-theme-color) !important;
}
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: var(--secondary-theme-color) !important;
}
.dark-theme .mdc-switch:enabled .mdc-switch__track::before {
  background: rgba(255, 255, 255, 0.2) !important;
}
.mat-mdc-slide-toggle {
  --mdc-switch-unselected-handle-color: #fafafa;
  --mdc-switch-unselected-hover-handle-color: #e0dcdc;
  --mdc-switch-unselected-pressed-handle-color: #e0dcdc;
}

.mdc-switch:enabled .mdc-switch__track::after {
  background-color: rgba(97, 97, 255, 0.2) !important;
}
.gene-card-title h5 {
  color: var(--subtitle-main-color) !important;
  font-weight: 400;
}
.mat-mdc-option {
  color: var(--text-main-color) !important;
  background: var(--filter-bg-theme-color) !important;
}
.dark-theme div.mat-mdc-select-panel {
  background-color: #3a3a3a !important;
}

::ng-deep
  .dark-theme
  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(
    .mat-mdc-option-multiple
  ) {
  background: #3a3a3ae6 !important;
}
::ng-deep .dark-theme.mdc-list-item.mdc-list-item--selected {
  background: #3a3a3ae6 !important;
}

.dark-theme .mat-option:focus:not(.mat-option-disabled),
.dark-theme .mat-option:hover:not(.mat-option-disabled) {
  background: #3a3a3ae6;
}

.loading-launcher {
  width: 60px;
  height: 60px;
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading-bar-launcher {
  display: inline-block;
  width: 8px;
  height: 36px;
  border-radius: 4px;
  animation: loading 1s ease-in-out infinite;
}

.loading-bar-launcher:nth-child(1) {
  background-color: var(--main-theme-color);
  animation-delay: 0;
}

.loading-bar-launcher:nth-child(2) {
  background-color: var(--main-theme-color);
  animation-delay: 0.09s;
}

.loading-bar-launcher:nth-child(3) {
  background-color: var(--main-theme-color);
  animation-delay: 0.18s;
}

.loading-bar-launcher:nth-child(4) {
  background-color: var(--main-theme-color);
  animation-delay: 0.27s;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 30px !important;
  padding: 0px 12px !important;
  font-size: 14px !important;
}

@keyframes loading {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1, 2.2);
  }
  40% {
    transform: scale(1);
  }
}
.mat-progress-bar {
  height: 3px !important;
}
/* pagination */
.paginator_with_pageOptions_container {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
}
.paginator_with_pageOptions_container .mat-mdc-form-field-infix {
  width: 150px !important;
}
.custom_paginator_option .mat-pseudo-checkbox,
.paginator_with_pageOptions_container .mat-mdc-paginator-page-size {
  display: none !important;
}
/* pagination */
/* custom table - start */
.custom_table-container {
  position: relative;
  overflow-x: auto;
  min-height: 300px;
  max-height: 600px;
  overflow-y: auto;
}
.custom_table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.custom_table thead th,
.custom_table tbody td {
  box-sizing: border-box;
  font-size: 14px;
  text-align: left;
  min-width: 140px;
  padding: 8px 16px;
  max-width: 400px;
  /* vertical-align: top; */
}
.custom_table_sort-icon {
  margin: -2px -2px -2px 2px;
  cursor: pointer;
  transform: rotate(0);
  transition: transform 0.3s;
}
.custom_table_sort-icon.active {
  transform: rotate(180deg);
}
.custom_table thead {
  position: sticky;
  top: -1px;
  z-index: 10;
}
.custom_table tbody td {
  padding: 12px 16px;
}
.custom_table tbody td .percentage {
  width: fit-content;
  padding: 1px 5px 2px 5px;
  border-radius: 5px;
  font-size: 13px;
}
.custom_table tbody td .positive {
  width: fit-content;
  padding: 1px 5px 2px 5px;
  border-radius: 5px;
  background: linear-gradient(to left, #d5ffd4ad, #ddfffdae);
  color: #056119;
  font-size: 13px;
}
.dark-theme .ai_analysis_additional_line {
  color: #ffffffe3 !important;
}
.dark-theme .ai_analysis_additional_box-line .draft-variable-values h2 {
  color: #ffffffe3 !important;
}

.custom_table tbody td .negative {
  width: fit-content;
  padding: 1px 5px 2px 5px;
  border-radius: 5px;
  background: linear-gradient(to left, #ffd4d472, #ffefdd8a);
  color: #610505;
  font-size: 13px;
}
.custom_table th:last-child,
.custom_table td:last-child {
  border-right: none;
}
.custom_table thead th > div {
  min-height: 20px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.custom_table thead th {
  font-weight: 500;
  color: #5f5d6e;
  background-color: #f7f8fa;
  box-sizing: border-box;
}
.custom_table thead th:first-child {
  border-radius: 10px 0 0 10px;
}
.custom_table thead th:last-child {
  border-radius: 0 10px 10px 0;
}
.custom_table tbody tr {
  border-bottom: 2px solid var(--border-color-theme-accent2);
}
.custom_table tbody tr .flex-container {
  display: flex;
  align-items: center;
}
.custom_table tbody tr.expandable-row {
  border: none;
}
.custom_table tbody tr.expandable-row.open {
  border-bottom: 2px solid #f7f8fa;
}
.custom_table tbody tr.expandable-row td:not(.custom_table_inner_table td) {
  padding: 0;
}
.custom_table tbody .expandable-row .expandable-row-content_container {
  overflow: hidden;
  max-height: 0;
  padding: 0;
}
.custom_table tbody .expandable-row.open .expandable-row-content_container {
  max-height: 400px;
}
.custom_table
  tbody
  .expandable-row
  .expandable-row-content_container
  .custom_table-container {
  max-height: 400px;
}
.custom_table .custom_table_item-sticky {
  padding-left: 0;
  display: flex;
  justify-content: flex-start;
  width: 100px;
  min-width: 100px !important;
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #fff;
}
.custom_table thead .custom_table_item-sticky {
  background-color: #f7f8fa;
}
.custom_table .custom_table_item-sticky p {
  display: flex;
  align-items: center;
  background: linear-gradient(97.33deg, #dcecff 0%, #dae4ff 97.91%);
  border: 1px solid #e3f1f9;
  padding: 6px 16px;
  margin: -4px 0;
  color: #000;
  cursor: pointer;
  border-radius: 6px;
}
.custom_table .custom_table_item-sticky.replayButton p {
  padding: 6px 10px;
}
.custom_table thead .custom_table_item-sticky p {
  opacity: 0;
  padding: 0;
}
.custom_table .custom_table_item-sticky svg {
  height: 14px;
  margin-right: 6px;
  fill: #000;
}
.custom_table .custom_table_item-sticky.replayButton svg {
  width: 14px;
}
.custom_table-newFlag {
  color: #003dff;
  font-size: 14px;
  font-weight: 500;
  padding: 2px 4px;
  border-radius: 5px;
  background: linear-gradient(114.5deg, #dde0ff 0%, #d4ecff 102.61%);
  border: 1px solid #c4c4ff;
  line-height: 1;
}
.custom_table-editButton {
  font-size: 16px;
  cursor: pointer;
}
.custom_table-buttons_container {
  display: flex;
  align-items: center;
  gap: 26px;
}
.custom-page-description {
  margin: 0;
  opacity: 0.7;
}
.custom-buttons_container button:disabled,
.custom-button:disabled,
button.btn_withoutIcon:disabled,
.custom_table-editButton.disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: 0.5;
}
.custom_table .material-icons {
  font-size: 18px;
  margin-left: 5px;
  cursor: pointer;
  line-height: 1;
  height: unset;
  width: unset;
}
.custom_table .svg-logo {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0;
}
.countryFlag {
  display: flex;
  align-items: center;
  gap: 6px;
}
.custom_table .countryFlag .fi {
  width: 18px;
  height: 18px;
}
.custom_table .countryFlag .fi + span {
  white-space: nowrap;
}
.custom_table .svg-logo.svg-logo-browser {
  margin-left: 10px;
}
.custom_table .text_align-right > div {
  display: flex;
  justify-content: flex-end;
}
.heatmap_link {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.heatmap_link.disabled {
  cursor: no-drop;
}
.screen-recorder-heatmaps_list-item .spinner,
.heatmap_link .spinner {
  stroke: #6161ff;
  animation: rotator 1.4s linear infinite;
  position: relative;
  left: 5px;
}
@keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}
.screen-recorder-heatmaps_list-item .path,
.heatmap_link .path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: dash 1.4s ease-in-out infinite,
    colors 5.6s ease-in-out infinite;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

.custom_table .heatmap_link mat-icon {
  font-size: 17px;
  position: relative;
  top: 2px;
}
.custom_table .text_align-right *,
.custom_table .text_align-right {
  text-align: right !important;
}
.custom_table .text_align-center *,
.custom_table .text_align-center {
  text-align: center !important;
}
.table_arrow_icon {
  margin-left: 4px;
  transform: translateY(0) rotate(0);
  transition: transform 0.3s;
}
.table_arrow_icon.turned_left {
  transform: translateY(-4px) rotate(180deg);
}
.mdc-dialog__surface {
  display: block;
  padding: 24px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}
.table_arrow_icon svg {
  width: 20px;
  height: 20px;
}
div .width50 {
  width: 50px !important;
}
div .minWidth20 {
  min-width: 20px !important;
}
div .minWidth60 {
  min-width: 60px !important;
}
div .minWidth100 {
  min-width: 100px !important;
}
div .minWidth120 {
  min-width: 120px !important;
}
div .minWidth150 {
  min-width: 150px !important;
}
div .minWidth300 {
  min-width: 300px !important;
}
div .minWidth400 {
  min-width: 400px !important;
}
div .maxWidth60 {
  max-width: 60px !important;
}
div .maxWidth80 {
  max-width: 80px !important;
}
div .maxWidth100 {
  max-width: 100px !important;
}
div .maxWidth120 {
  max-width: 120px !important;
}
div .maxWidth140 {
  max-width: 140px !important;
}
div .maxWidth200 {
  max-width: 200px !important;
}
div .maxWidth300 {
  max-width: 300px !important;
}
div .maxWidth400 {
  max-width: 400px !important;
}
.maxHeight400 {
  max-height: 400px !important;
}
/* custom table - end */
/* header button - start */
.custom-input_field-text-multiple_searchbox {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}
.custom-buttons_container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 10px !important;
}
.expandable-row-buttons_container {
  padding-top: 10px;
  justify-content: flex-end;
  align-items: center;
}
.custom-buttons_container.justify_flex_end {
  justify-content: flex-end;
}
div div .custom-button,
.custom-buttons_container button {
  background-color: var(--filter-bg-theme-color) !important;
  border-radius: 4px;
  box-shadow: 0px 1px 1px 0px #0000001f, 0px 0px 0px 1px #3c425729,
    0px 2px 5px 0px #3c425714 !important;
  color: var(--text-main-color) !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  padding: 3px 8px 3px 4px;
  display: flex;
  align-items: center;
  margin: 1px;
  min-width: 46px;
  height: unset !important;
}
.custom-button.btn_withoutIcon .mdc-button__label,
.custom-buttons_container button .mdc-button__label {
  color: #000;
}
.dark-theme .custom-button.btn_withoutIcon .mdc-button__label,
.dark-theme .custom-buttons_container button .mdc-button__label {
  color: #fff;
}
.custom-buttons_container button mat-icon {
  margin: 0 !important;
  padding: 0 !important;
  height: unset !important;
  width: unset !important;
}
div div .custom-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  border: none !important;
  outline: none !important;
}
.dark-theme div div .custom-button,
.dark-theme .custom-buttons_container button {
  box-shadow: 0px 1px 1px 0px #0000001f, 0px 0px 0px 1px #ffffff29,
    0px 2px 5px 0px #ffffff14 !important;
}
.dark-theme .pov-pageBarChart-main-charts-bar {
  background: linear-gradient(0deg, #ffffff00 0%, #94b2ff 90%) !important;
}
.dark-theme .pov-pageBarChart-main-charts-bar.defaultCol {
  background: linear-gradient(0deg, #ffffff00 0%, #a5acb8 90%) !important;
}

::ng-deep.dark-theme .frq-pageBarChart-main-charts-bar {
  background: linear-gradient(0deg, #ffffff00 0%, #94b2ff 100%) !important;
}
::ng-deep .dark-theme .loader.progress {
  background-color: #2a2a2a !important;
  background-image: linear-gradient(
    90deg,
    rgb(225 220 220 / 0%),
    rgb(255 255 255 / 9%),
    rgba(255, 255, 255, 0)
  ) !important;
}
.dark-theme .skeleton-loader {
  background-color: #2a2a2a !important;
  background-image: linear-gradient(
    90deg,
    rgb(225 220 220 / 0%),
    rgb(255 255 255 / 1%),
    rgb(255 255 255 / 0%)
  ) !important;
}
.dark-theme .skeleton-loader.progress:before {
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgb(255 255 255 / 5%),
    rgba(255, 255, 255, 0)
  ) !important;
}
.dark-theme .loader-html .skeleton-loader {
  background-color: rgba(55, 71, 79, 0.1) !important;
  background-image: unset !important;
}
.dark-theme .loader-html .skeleton-loader.progress:before {
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgb(255 255 255 / 5%),
    rgba(255, 255, 255, 0)
  ) !important;
}
div div .custom-button.custom-button_clear-filters {
  box-shadow: none !important;
  padding: 6px 8px !important;
  background: transparent !important;
  font-weight: 300;
}
.custom-button.custom-button_clear-filters .mdc-button__label {
  color: #635bff;
}
.dark-theme .custom-button.custom-button_clear-filters .mdc-button__label {
  color: #fff;
  opacity: 0.7;
}
div div .custom-button.right_side_icon,
.custom-buttons_container button.right_side_icon {
  padding-right: 4px;
  padding-left: 8px;
}
.custom-buttons_container button:only-child {
  margin-left: auto;
}
.custom-button.btn_withoutIcon,
.custom-buttons_container button.btn_withoutIcon {
  padding: 4px 8px 4px 8px !important;
  height: 24px;
}

.custom-button mat-icon,
.custom-buttons_container button mat-icon {
  width: unset;
  height: unset;
  font-size: 18px !important;
  margin-right: 2px !important;
  margin-left: 0px !important;
}
.custom-button-right-arrow-down {
  padding-left: 6px !important;
}
.custom-button-right-arrow-down::after {
  content: "";
  display: block;
  background-image: url("./assets/img/arrow_down.svg");
  background-position: -3px -3px;
  background-size: 24px;
  width: 18px;
  height: 18px;
  margin: 0;
  position: relative;
  right: -4px;
}
.dark-theme .custom-button-right-arrow-down::after {
  background-image: url("./assets/img/arrow_down_light.svg");
}
::ng-deep div div .status-button:not(.dark-theme .status-button) {
  box-shadow: none !important;
  background-color: #f7f7f7 !important;
}
.custom-input_container_with_search {
  position: relative;
}
.custom-input_container_with_search svg {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.custom-input_field-label {
  display: block;
  font-size: 12px;
  margin-bottom: 5px;
}
.custom-input_field {
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  padding: 4px 6px 4px 30px !important;
  outline: none;
  border-radius: 4px;
  border: 1px solid #dde2e9;
  color: var(--text-main-color);
  box-shadow: 0px 1px 1px 0px #e4e4e48c;
  background: var(--filter-bg-theme-color);
}
.dark-theme .custom-input_field {
  border: 1px solid #d4dae105;
  box-shadow: none;
}
.custom-input_field_withoutIcon .custom-input_field {
  padding: 4px 8px !important;
}
.custom_horizontal_option_multi-select-container,
.custom_horizontal_option_select-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #f1f1f1;
  border-radius: 4px;
  width: fit-content;
  margin: 0 1px;
}
.dark-theme .custom_horizontal_option_multi-select-container,
.dark-theme .custom_horizontal_option_select-container {
  background-color: #3a3a3a;
}
.custom_horizontal_option_active {
  background-color: #fff;
  outline: 1px solid #d5dbe1;
  outline-offset: -1px;
  border-radius: 4px;
  position: absolute;
  top: 0;
  bottom: 0;
  transition: all 0.3s;
  box-shadow: 0px 2px 5px 0px #3c425714 !important;
}
.dark-theme .custom_horizontal_option_active {
  background-color: #646464;
  outline: 1px solid #a2a2a2;
}
.dark-theme .custom_horizontal_option_select p span {
  color: #c9c9c9;
}
.custom_horizontal_option_select {
  padding: 4px 8px;
  z-index: 1;
  cursor: pointer;
}
.custom_horizontal_option_multi-select-container
  .custom_horizontal_option_select {
  border-radius: 4px;
  transition: all 0.2s;
  padding: 4px 7px;
  border: 1px solid transparent;
}
.disabled {
  opacity: 0.5;
  cursor: default;
}
.custom_horizontal_option_select.active {
  background-color: #fff;
  border-color: #d5dbe1;
}
.dark-theme .custom_horizontal_option_select.active {
  background-color: #646464;
  border-color: #a2a2a2;
}
.custom_horizontal_option_select p {
  margin: 0;
  font-size: 14px;
  display: flex;
  gap: 4px;
  align-items: center;
  white-space: nowrap;
}
.custom_horizontal_option_select p span {
  color: #5d5d5d;
  font-size: 12px;
}
/* header button - end */
/* custom option picker - start */
/* datepicker */
.custom_datepicker {
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 5px -1px #0000000a;
  padding: 0 10px 0 15px;
  border-radius: 7px;
  position: relative;
  height: 26px;
  max-width: 160px;
}
.custom_datepicker .mat-select-trigger {
  margin-top: 4px;
}
.custom_datepicker .mat-input-element {
  position: relative;
  top: 1px;
}
.custom_option_datepicker-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}
.custom_option_datepicker-container input {
  border: none;
  background: transparent;
  width: 80px;
  position: relative;
  top: 1px;
}
.custom_option_datepicker-container .custom_option_picker-label {
  top: 0 !important;
}
.mat-mdc-icon-button .mat-mdc-button-touch-target {
  height: unset;
  top: 2px;
  bottom: 2px;
  left: unset;
  right: 4px;
}
.custom_option_datepicker-container .mat-mdc-button-base {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: unset;
  width: unset;
}
.dark-theme .custom_option_datepicker-container .mat-mdc-button-base {
  color: #fff;
}
.dark-theme .custom_option_datepicker-container input {
  color: #fff;
}
.custom_option_datepicker-container .mat-mdc-button-base svg {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 6px;
  right: 6px;
}
.mat-mdc-button-persistent-ripple {
  display: none !important;
}
.custom_option_datepicker-container
  .mat-mdc-icon-button
  .mat-mdc-focus-indicator,
.custom_option_datepicker-container
  .mat-mdc-icon-button
  .mat-mdc-button-ripple {
  width: 22px !important;
  height: 22px !important;
  position: absolute !important;
  top: 3px !important;
  right: 3px !important;
  left: unset !important;
  bottom: unset !important;
}
.dark-theme .mdc-button .mdc-button__label {
  color: #fff;
}
.dark-theme .mat-mdc-dialog-container .mdc-dialog__surface {
  color: #fff;
}
.dark-theme .mdc-dialog__container .mdc-dialog__surface input {
  outline: 1px solid #424140 !important;
  background: #303030 !important;
  color: #fff;
}
::ng-deep.new-nudge-c .mdc-dialog__container .mdc-dialog__surface input {
  outline: 0px solid #424140 !important;
}
.mdc-dialog__container mat-icon {
  color: #000 !important;
}
.dark-theme .mdc-dialog__container mat-icon {
  color: #fff !important;
}
.mdc-dialog__container .mdc-text-field {
  height: 32px;
  border: 1px solid #464443;
  border-radius: 7px;
  padding: 0 14px 0 10px;
  background: transparent !important;
}
.dark-theme .mdc-dialog__container .mdc-text-field {
  background: transparent !important;
}
.mdc-dialog__container .mdc-text-field .mdc-line-ripple,
.mdc-dialog__container .-webkit-calendar-picker-indicator {
  display: none !important;
}
.custom_option_picker_with_absolute_label .mat-mdc-form-field {
  width: 100%;
}
.custom_option_picker_with_absolute_label .mat-mdc-form-field .mdc-text-field {
  height: 26px !important;
  background: transparent !important;
  background-color: transparent !important;
}
input[type="datetime-local" i]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 10px;
  height: unset;
  width: unset;
  background-size: 16px;
  background-position: center right;
}
.dark-theme .mat-mdc-menu-item,
.dark-theme .mat-mdc-menu-item:visited,
.dark-theme .mat-mdc-menu-item:link {
  background: #3a3a3a;
  color: #fff;
  min-height: 44px;
}
.mat-mdc-menu-content {
  padding: 0px !important;
}
.dark-theme .mat-mdc-menu-item .mat-icon {
  color: #fff;
}
.dark-theme .mat-mdc-menu-item:hover {
  background: #5f5f5f !important;
  color: #fff;
}
.dark-theme .mat-mdc-menu-item:hover .mat-icon {
  color: #fff;
}
.mat-mdc-select-arrow svg {
  fill: currentColor;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: black;
}
.dark-theme .mat-mdc-select-arrow svg {
  fill: white;
}
.mat-mdc-tab-header-pagination-chevron {
  border-color: var(--text-main-color) !important;
}
.selected_device mat-icon {
  color: #000 !important;
  width: 18px !important;
  height: 18px !important;
  font-size: 18px !important;
  position: relative !important;
  top: 4px !important;
}
.dark-theme .selected_device mat-icon {
  color: #fff !important;
}
/* .dark-theme
  .mdc-dialog__container
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #303030 !important;
} */
.mdc-dialog__container .mat-mdc-form-field-infix {
  padding: 0px !important;
  position: relative;
  top: 2px;
}
.mat-calendar-body-selected {
  background-color: #5469d4 !important;
}
.custom_option_picker {
  box-shadow: 0px 1px 1px 0px #0000001f, 0px 0px 0px 1px #3c425729,
    0px 2px 5px 0px #3c425714;
  margin: 2px;
  padding: 0 6px;
  border-radius: 4px;
  font-size: 14px;
  background: var(--filter-bg-theme-color);
  overflow: hidden;
}
.dark-theme .custom_option_picker {
  background: #303030;
}
.custom_option_picker .mat-mdc-form-field-focus-overlay {
  display: none !important;
}
.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
  transform: none !important;
}
.dark-theme .custom_option_picker {
  box-shadow: 0px 1px 1px 0px #0000001f, 0px 0px 0px 1px #ffffff29,
    0px 2px 5px 0px #ffffff14;
}
.dark-theme .custom_option_picker-label {
  opacity: 0.7;
}
.mat-mdc-select-value-text {
  color: var(--text-main-color);
}
.mat-mdc-option .mdc-list-item__primary-text {
  white-space: nowrap !important;
}
.custom_option_picker-label {
  margin-right: 4px;
  opacity: 0.5;
  color: var(--text-main-color);
  white-space: nowrap;
  word-break: keep-all;
}
.custom_option_picker_with_absolute_label {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.custom_option_picker_with_absolute_label .custom_option_picker-label {
  font-size: 12px;
}
.custom_option_picker .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.custom_option_picker .mat-mdc-select {
  height: 24px;
}
.custom_option_picker .mat-mdc-select-trigger {
  height: inherit;
}
.custom_option_picker .mat-mdc-select-value-text {
  position: relative;
  top: 0px;
}
.custom_option_picker .mat-mdc-form-field-infix {
  border: none;
  width: 140px;
  padding: 0 !important;
  min-height: auto;
}
.custom_option_picker.custom_option_picker_widthauto .mat-mdc-form-field-infix {
  width: auto;
}
.custom_option_picker.custom_option_picker_width50 .mat-mdc-form-field-infix {
  width: 50px;
}
.custom_option_picker.custom_option_picker_width60 .mat-mdc-form-field-infix {
  width: 60px;
}
.custom_option_picker.custom_option_picker_width70 .mat-mdc-form-field-infix {
  width: 70px;
}
.custom_option_picker.custom_option_picker_width80 .mat-mdc-form-field-infix {
  width: 80px;
}
.custom_option_picker.custom_option_picker_width90 .mat-mdc-form-field-infix {
  width: 90px;
}
.custom_option_picker.custom_option_picker_width100 .mat-mdc-form-field-infix {
  width: 100px;
}
.custom_option_picker.custom_option_picker_width110 .mat-mdc-form-field-infix {
  width: 110px;
}
.custom_option_picker.custom_option_picker_width120 .mat-mdc-form-field-infix {
  width: 120px;
}
.custom_option_picker.custom_option_picker_width123 .mat-mdc-form-field-infix {
  width: 123px;
}
.custom_option_picker.custom_option_picker_width130 .mat-mdc-form-field-infix {
  width: 130px;
}
.custom_option_picker.custom_option_picker_width140 .mat-mdc-form-field-infix {
  width: 140px;
}
.custom_option_picker.custom_option_picker_width150 .mat-mdc-form-field-infix {
  width: 150px;
}
.custom_option_picker.custom_option_picker_width160 .mat-mdc-form-field-infix {
  width: 160px;
}

.custom_option_picker.custom_option_picker_width170 .mat-mdc-form-field-infix {
  width: 170px;
}
.dark-theme .mat-mdc-menu-panel {
  background: #3a3a3a !important;
}

.custom_option_picker .mat-mdc-form-field-infix,
.custom_option_picker .mat-mdc-form-field-wrapper {
  padding: 0;
}
.custom_option_picker .mat-focused .mat-select-placeholder,
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87) !important;
}

.custom_option_picker .mat-mdc-select-arrow {
  border: none !important;
  background-image: url("./assets/img/arrow_down.svg");
  background-position: -3px -3px;
  background-size: 24px;
  width: 18px;
  height: 18px;
  margin: 0;
}
.dark-theme .custom_option_picker .mat-mdc-select-arrow {
  background-image: url("./assets/img/arrow_down_light.svg");
}

.custom_option_picker .mat-mdc-select-arrow svg {
  display: none !important;
}

.custom_option_picker .mat-mdc-form-field-underline {
  display: none !important;
}
.custom_option_picker
  .mat-mdc-form-field-type-mat-select
  .mat-mdc-form-field-label {
  top: 16px;
}
.custom_option_picker .mat-mdc-text-field-wrapper {
  padding: 0px 5px;
  padding-right: 0px;
  background: transparent !important;
  border: none;
}
.date-controller-container .mdc-line-ripple,
.custom_option_picker .mdc-line-ripple {
  display: none !important;
}
.cdk-overlay-pane {
  min-width: 145px !important;
}
.mat-mdc-option > span.mdc-list-item__primary-text {
  font-size: 14px !important;
}
.mat-mdc-option {
  min-height: 42px !important;
}
.cdk-overlay-pane mat-tooltip-component {
  margin: auto;
}
.cdk-overlay-pane mat-tooltip-component {
  margin: auto;
}

.custom_option_picker
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label {
  display: none;
  /* transform: none;
  width: 100%;
  font-size: 14px;
  color: #5d5d5d !important; */
}
.tab_option_picker {
  display: flex;
  align-items: center;
  gap: 4px;
}
.tab_option_picker .tab_option_picker-label {
  font-size: 14px;
}
.tab_option_picker
  .mat-form-field-appearance-fill
  .mat-mdc-select-arrow-wrapper {
  transform: none;
}
.tab_option_picker .mdc-floating-labe {
  display: none;
}
.tab_option_picker .custom_option_picker .mat-mdc-form-field-infix {
  width: 200px;
}
.custom-slider p {
  display: inline;
  margin-right: 12px;
  margin-left: 10px;
  font-size: 14px;
}
.custom-slider mat-slide-toggle {
  top: 2px !important;
  position: relative;
}
/* custom option picker - end */
/* modal style - start */
.confirmation_modal {
  display: flex;
  flex-direction: column;
}
.recorder_modal_text {
  margin-bottom: 20px;
  font-size: 16px;
}
.recorder_modal .custom-buttons_container button.no_icon_button {
  padding: 4px 6px;
}
.recorder_modal .recorder_modal_title {
  margin: 0 0 20px 0;
  font-size: 18px;
}
.recorder_modal_subtitle {
  margin: 0;
  font-size: 14px;
}
.recorder_modal-hr {
  width: 100%;
  height: 1px;
  background-color: #706c62;
  margin: 20px 0;
}
.dark-theme .recorder_modal-hr {
  background-color: #424140;
}
.dark-theme .seo_cancel {
  border-color: transparent !important;
}
.recorder_modal .button_container {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
.recorder_modal button:not(.mdc-switch),
.recorder_modal button:not(.recording_ended_modal-button) {
  background-color: transparent !important;
  color: #000 !important;
  min-width: 60px;
  width: fit-content;
  box-shadow: none !important;
  border-radius: 10px;
  padding: 0 10px;
}
.recorder_modal button span {
  font-size: 14px;
}
.recorder_modal button.primary {
  background-color: #6161ff !important;
  border-color: #6161ff !important;
  color: #fff !important;
}
.recorder_modal button.primary:focus {
  background-color: #4848ff !important;
  border-color: #4848ff !important;
}
.recorder_modal button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.recorder_modal .recorder_modal_input_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
.recorder_modal label {
  font-size: 12px;
  display: inline-block;
  margin-bottom: 2px;
  color: #706c62;
}
.dark-theme .recorder_modal label {
  color: #fff;
}
.recorder_modal .recorder_input {
  position: relative;
  display: inline-block;
  border-radius: 8px;
  padding: 8px 10px;
  font-size: 14px;
  border: none;
  outline: 1px solid #706c62;
  outline-offset: -1px;
}
.recorder_modal .recorder_input.error {
  outline: 1px solid #ff0000;
}
.recorder_modal_options_container {
  display: flex;
  flex-direction: column;
}
.recorder_modal mat-select .mat-select-value mat-icon {
  width: 20px;
  height: 20px;
  font-size: 20px;
  position: relative;
  top: 4px;
}
.recorder_modal .recorder_modal_options_container .mat-select-value {
  position: relative;
  top: -2px;
  left: 8px;
  font-size: 14px;
}
.recorder_modal
  .recorder_modal_options_container
  .mat-select-value-text:not(:has(mat-icon)) {
  position: relative;
  top: 2px;
  left: 2px;
}
.recorder_modal .recorder_modal_options_container .mat-form-field-infix {
  border: 1px solid #706c62 !important;
  border-radius: 8px;
}
.recorder_modal .recorder_modal_options_container .mat-select-arrow-wrapper {
  position: relative;
  right: 8px;
}
.recorder_modal
  .recorder_modal_options_container
  .mat-form-field-subscript-wrapper,
.recorder_modal .recorder_modal_options_container .mat-form-field-underline {
  display: none !important;
}
.recorder_modal_options_container-error {
  font-size: 12px;
  color: #ff0000;
  margin-top: 4px;
}
/* modal style - end */
.input_eye_icon {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("./assets/img/eye-off.svg");
}
.input_eye_icon.show {
  background-image: url("./assets/img/eye_show.svg");
}
/* custom_menu_with_inputs - start */
.custom_menu_with_inputs {
  background-color: #f1f1f1;
}
.dark-theme .custom_menu_with_inputs {
  background-color: #3a3a3a;
}
.custom_menu_with_inputs input {
  font-size: 14px;
  padding: 10px;
  width: 50%;
  outline: none;
  border: 1px solid var(--border-color-theme);
  background-color: transparent;
}
.custom_menu_with_inputs button {
  width: 100%;
  text-align: center;
  font-size: 14px;
  border: 1px solid var(--border-color-theme);
  background-color: transparent;
  padding: 10px 6px;
  cursor: pointer;
}
/* custom_menu_with_inputs - end */
.cursor_pointer {
  cursor: pointer;
}
.custom_break-word {
  word-break: break-word;
}
.custom_price-container {
  background: linear-gradient(114.5deg, #ffefdd 0%, #ffd4d4 102.61%);
  border: 1px solid #ffa3a3;
  color: #610505;
  padding: 6px;
  border-radius: 5px;
  font-size: 13px;
}
.loading-data-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f0f2f71f;
  cursor: wait;
  backdrop-filter: blur(1px);
  z-index: 1000;
}
.dark-theme .loading-data-overlay {
  background: #1b1b1b26 !important;
}
.dark-theme .mat-progress-bar-buffer {
  background-color: #252525 !important;
}
.dark-theme .mat-progress-bar-fill::after {
  background-color: #ffffff4f !important;
}
.dark-theme .mat-option.mat-option-disabled {
  color: rgb(255 255 255 / 38%) !important;
}
.hover-unhide {
  position: relative;
}

.general-hide-scrollbar {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--component-container-theme-color);
  width: 5px;
}

.general-hide-scrollbar.h {
  right: 0;
  left: 0;
  top: unset;
  bottom: 0;
  height: 5px;
  width: unset;
}

.hover-unhide:hover .general-hide-scrollbar {
  display: none;
}

.subtitle-h2 {
  font-size: 16px !important;
  margin: 0;
  font-weight: 400;
  color: var(--subtitle-main-color);
}

/* Style for pagetype tags */
div div .pagetype_tag {
  padding: 1px 4px;
  border-radius: 4px;
  margin: -1px 0;
  width: fit-content;
  color: #000;
}
.cdk-global-scrollblock {
  position: unset;
  width: unset;
  overflow-y: unset;
}
.dark-theme .custom_option_picker .mat-focused .mat-select-placeholder,
.dark-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: white !important;
}
.dark-theme .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #252525 !important;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
::ng-deep .mat-form-field.mat-focused .mat-form-field-label {
  color: var(--text-main-color) !important;
}
.dark-theme .custom_table thead th {
  color: #ffffff;
  background-color: #3a3a3a;
}
.dark-theme .custom_table_sort-icon path {
  stroke: #fff;
}
.dark-theme google-chart.chart svg > rect {
  fill: transparent;
}
.dark-theme .custom_table tbody td .positive {
  background: #d5ffd42b !important;
  color: #57ff7c !important;
}
.dark-theme .custom_table tbody td .negative {
  background: #ffefdd26 !important;
  color: #ff9292 !important;
}
.custom_table tbody tr {
  color: #000000ab;
}
.custom_table tr td:first-child {
  color: #000000;
}
.dark-theme .custom_table tbody tr {
  color: #ffffffa1;
}
.dark-theme .custom_table tbody tr td:first-child {
  color: #ffffffcc;
}
.dark-theme .merchandise-filters-button {
  background: #3a3a3a !important;
}
.dark-theme .merchandise-new-filter {
  background: #3a3a3a !important;
}
.dark-theme .tooltip-text {
  color: #fff !important;
}
.dark-theme .custom_table tbody tr.expandable-row.open {
  border-bottom: 2px solid #f7f8fa14;
}
.dark-theme .preview-screenshot-box {
  border-color: transparent !important;
}
.dark-theme .mat-paginator {
  display: block;
  background: #252525;
  color: #fff;
}
.dark-theme .stat-container {
  color: #fff;
}
.dark-theme .stat-container span label {
  color: #fff;
}
.dark-theme .target-title {
  color: #fff;
}
.dark-theme .pageBarChart-skeleton-line-number {
  color: #fff !important;
}
.dark-theme .bug-charts-container-detail .bugPiechart-container::before {
  background: #e3e8ee14 !important;
}
.dark-theme .pageBarChart-main-charts-bar {
  background: linear-gradient(0deg, #ffffff00 0%, #94b2ff 100%) !important;
}
.dark-theme .custom_table .text_align-right *,
.dark-theme .custom_table .text_align-right {
  fill: white !important;
}
.dark-theme span.custom_keyword_tag {
  background: unset !important;
  color: var(--text-main-color) !important;
  opacity: 0.6 !important;
}
.dark-theme .draft-keyword {
  color: var(--text-main-color) !important;
  background: unset !important;
  font-weight: 400 !important;
}
.dark-theme .hours-container-arrow::after,
.dark-theme .hours-container-arrow::before {
  background: #fff !important;
}
.dark-theme .ai_analysis-page_inputs-container {
  background-color: #3a3a3a !important;
}
.dark-theme .ai_analysis_input_container label {
  color: #ffffff !important;
}
.dark-theme .ai_analysis_input input {
  border: 1px solid #e3e8ee36 !important;
  background: #404040 !important;
  color: #fff !important;
}
.dark-theme .preview-filter-container-page mat-button-toggle {
  background: #3a3a3a !important;
  color: #fff !important;
  border-color: #ffffff26 !important;
}
.dark-theme .preview-filter-container-page .mat-button-toggle-checked {
  color: #ffffff !important;
  border: 2px solid #635bff !important;
}
.dark-theme .preview-filter-container-page .mat-button-toggle-disabled {
  color: #ffffff7a !important;
}
.dark-theme .bug_tracking-charts-container-divider {
  background: #e3e8ee14 !important;
}
.dark-theme .diagnostic_overall_main_horizontal-line {
  border-color: #e3e8ee14 !important;
}
rect[fill="#c0c0c0"] {
  fill: #f1f1f1 !important;
}
rect[fill="#e6e6e6"] {
  fill: #f1f1f100 !important;
}
.dark-theme rect[fill="#b1b0b0"] {
  fill: #ffffff00 !important;
}

.dark-theme .custom_table .text_align-right .icon-tabler-chevron-down > path {
  fill: none !important;
}
.dark-theme .mat-menu-panel {
  background: #3a3a3a !important;
  border: 1px solid #ffffff17 !important;
}
.dark-theme .mat-menu-panel .mat-icon {
  color: #fff !important;
}
.dark-theme .mat-menu-panel span {
  color: #fff !important;
}
.dark-theme .product-details-header {
  background-color: #3a3a3a !important;
  border: 1px solid #ffffff29 !important;
}
.dark-theme .product-details-header .product-details-row {
  color: #ffffff !important;
}
.dark-theme .line-second,
.dark-theme .line-first {
  background: #ecf0f512 !important;
}
.mat-mdc-tab .mdc-tab__ripple::before {
  background-color: #ffffff00 !important;
}
.dark-theme .mat-mdc-tab-group,
.dark-theme .mat-mdc-tab-nav-bar {
  --mat-tab-header-active-ripple-color: #ffffff48 !important;
  --mat-tab-header-active-ripple-color: #ffffff48 !important;
  --mat-tab-header-inactive-ripple-color: #ffffff48 !important;
  --mat-tab-header-inactive-focus-label-text-color: #ffffff48 !important;
  --mat-tab-header-active-focus-label-text-color: #ffffff48 !important;
  --mat-tab-header-active-hover-label-text-color: #ffffff48 !important;
  --mat-tab-header-active-focus-indicator-color: #ffffff48 !important;
  --mat-tab-header-active-hover-indicator-color: #ffffff48 !important;
}
.dark-theme .mat-datepicker-content .mat-calendar {
  background: #3a3a3a !important;
  color: #fff !important;
}
.dark-theme .mat-datepicker-content .mat-calendar-arrow {
  fill: #fff !important;
}
.dark-theme .mat-datepicker-content span {
  color: #fff !important;
}
.dark-theme .mat-datepicker-content td {
  color: #fff !important;
}
.dark-theme
  .mat-calendar-body-disabled
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  color: #dddddd82 !important;
}
.dark-theme .mat-calendar-previous-button::after,
.dark-theme .mat-calendar-next-button::after {
  border-color: white;
}
.dark-theme .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(
    .mat-mdc-option-multiple
  ) {
  background: #e4e4e4ab !important;
}
.mat-mdc-option:hover {
  background: #e4e4e4ab !important;
}
.dark-theme .mat-mdc-option:hover {
  background: #585858ab !important;
}

.dark-theme
  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(
    .mat-mdc-option-multiple
  ) {
  background: #585858ab !important;
}
.dark-theme
  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  color: #fff !important;
}
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mat-pseudo-checkbox-minimal::after {
  color: #6161ff;
}
.dark-theme
  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mat-pseudo-checkbox-minimal::after {
  color: #fff;
}
.mat-calendar-body-selected,
.mat-focused .mat-form-field-underline .mat-form-field-ripple,
.mat-calendar-body-selected {
  background-color: #5469d4 !important;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #635bff !important;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87) !important;
  --mat-snack-bar-button-color: #fff !important;
}

.mat-mdc-snack-bar-container .mdc-button {
  min-width: 30px;
  font-size: 16px;
}
button#side-trigger {
  display: none;
  background: #fff;
  margin-left: 20px;
}
.dark-theme button#side-trigger {
  background-color: #333;
  color: #fff !important;
}
div.custom-matMenu-holder div.custom-matMenu-container > button {
  padding: 4px 6px 4px 6px !important;
  height: 24px !important;
}
.dark-theme div.custom-matMenu-holder div.custom-matMenu-container > button {
  background-color: #303030 !important;
}
.long-title {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.intercom-lightweight-app-launcher {
  position: fixed !important;
}

body.panel-open .intercom-lightweight-app-launcher {
  position: absolute !important;
}
.mat-mdc-tab-body-wrapper,
.mat-mdc-tab-body.mat-mdc-tab-body-active {
  padding-right: 20px !important;
  padding-left: 20px !important;
  margin-left: -20px !important;
  margin-right: -20px !important;
}
.mat-mdc-tab-body-content {
  overflow: unset !important;
}
@media only screen and (max-width: 1450px) {
  .custom_menu_with_inputs button,
  .custom_menu_with_inputs input {
    font-size: 12px;
    padding: 6px;
  }
  .custom_menu_with_inputs {
    max-width: 240px !important;
  }
  div div .custom-button.custom-button_clear-filters {
    padding: 8px !important;
  }
  .custom_table thead th,
  .custom_table tbody td {
    font-size: 10px;
    max-width: 280px;
  }
  p.custom-page-description {
    font-size: 12px;
  }
  .mat-mdc-option > span.mdc-list-item__primary-text {
    font-size: 10px !important;
  }
  div.custom-matMenu-holder div.custom-matMenu-container > button {
    padding: 2px 6px !important;
    height: 20px !important;
  }
}
@media only screen and (max-width: 1200px) {
  button#side-trigger {
    display: block;
    margin-top: auto;
  }
  .sidenav-tabs_container-title-container:has(.tab_option_picker) {
    flex-wrap: wrap;
    gap: 10px;
  }
  .nav-item .mdc-list-item__content {
    overflow: visible;
  }
}
@media only screen and (max-width: 840px) {
  .custom_table thead th,
  .custom_table tbody td {
    min-width: unset !important;
  }
  p.custom-page-description {
    font-size: 10px;
  }
}
@media only screen and (max-width: 620px) {
  .custom_option_datepicker-container {
    gap: 10px;
  }
}
@media only screen and (max-width: 520px) {
  .paginator_with_pageOptions_container {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
  .paginator_with_pageOptions_container .mat-mdc-paginator-container {
    min-height: 30px !important;
  }
}
