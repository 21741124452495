@media only screen and (max-width: 1450px) {
  .mat-sidenav-content_container {
    margin: 5px 10px 10px 10px !important;
    min-height: calc(100vh - 40px) !important;
  }
  .mat-drawer {
    zoom: var(--zoom-val);
  }
  .dashboard-header-intro {
    zoom: var(--zoom-val);
  }
  .mdc-switch {
    width: 26px !important;
  }
  .mdc-switch .mdc-switch__track {
    height: 10px !important;
  }
  .mdc-switch .mdc-switch__handle {
    width: 14px !important;
    height: 14px !important;
  }
  .toolbar .mdc-switch--selected .mdc-switch__handle-track {
    transform: translateX(12px) !important;
  }
  /* .mdc-switch--selected .mdc-switch__handle-track {
    transform: translateX(180%) !important;
  } */
  .mat-toolbar .user-button {
    top: 15px !important;
    right: 15px !important;
    padding: 8px;
  }
  .dashboard-header-intro-inner h1 {
    margin-bottom: 22px !important;
    font-size: 14px !important;
  }
  .dashboard-header-intro-inner h2 {
    font-size: 24px !important;
  }
  .dashboard-header-intro-inner p {
    font-size: 12px !important;
  }
  .mat-mdc-paginator-container {
    font-size: 10px !important;
  }
  .custom_table-container {
    min-height: 200px !important;
    max-height: 400px !important;
  }
  .mat-mdc-paginator-container .mat-mdc-icon-button svg,
  .mat-mdc-paginator-container .mat-mdc-icon-button img {
    width: 20px !important;
    height: 20px !important;
  }
  /* .sidenav-tabs_container-title-container {
    zoom: var(--zoom-val);
  } */

  /* login --- */
  .gene-login-wrapper .login-logo svg {
    width: 100px !important;
  }
  .gene-login-wrapper .mat-mdc-form-field-infix {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    min-height: 40px !important;
  }
  .gene-login-wrapper input {
    font-size: 14px !important;
  }
  .gene-login-wrapper .forgot-option a {
    font-size: 12px !important;
  }
  .gene-login-wrapper .mat-mdc-button.mat-mdc-button-base {
    height: 30px !important;
    font-size: 12px !important;
  }
  .login-with_email-button span.mdc-button__label {
    font-size: 12px !important;
  }
  .login-with_email-button svg {
    width: 16px !important;
    height: 16px !important;
  }
  /* login --- */

  .pov-bar-charts-holder {
    zoom: var(--zoom-val);
  }
  .performance-box-orders_headless_table-item p,
  .performance-box-orders_table-item p {
    font-size: 10px !important;
  }
  .performance-box-orders_content .width80 {
    min-width: 60px !important;
  }
  .performance-box-orders_content .width120 {
    min-width: 95px !important;
  }
  .performance-box-orders_content .width70 {
    min-width: 55px !important;
  }
  .performance-box-orders_headless_table {
    gap: 30px !important;
  }
  google-chart.chart g text {
    font-size: 8px;
  }
  .tooltip-text {
    padding: 0 12px !important;
    font-size: 9px !important;
  }
  .diagnostic_overall_main_header-child {
    padding: 12px 15px !important;
  }
  .custom-input_field-label {
    font-size: 10px !important;
  }
  .custom-input_field_withoutIcon .custom-input_field {
    padding: 4px 5px !important;
    font-size: 11px !important;
  }
  .bug-table-percentages_toogle-container {
    font-size: 10px !important;
  }
  .custom_datepicker {
    height: 20px !important;
  }
  .gene-card-title h5 {
    font-size: 13px !important;
    font-weight: 500;
  }
  .diagnostic_overall_main_header-child-header {
    margin-bottom: 20px !important;
  }
  .diagnostic-overall-header_container .chart-main-container {
    height: 220px !important;
  }
  .chart-main-container > div:only-child > p:only-child {
    font-size: 12px !important;
  }
  .google-visualization-tooltip-item-list {
    padding: 0em !important;
    margin: 0 !important;
  }
  .google-visualization-tooltip-item {
    padding: 0 !important;
  }
  .merchandise-chart-container h3 {
    font-size: 13px !important;
  }
  .merchandising-overview-title {
    font-size: 13px !important;
  }
  .chart-item-container .chart-item-bar-value {
    font-size: 11px !important;
    gap: 3px !important;
  }
  div div .custom-button,
  .custom-buttons_container button {
    height: 20px !important;
  }
  .highest-impact-container .product {
    height: 50px;
  }
  .sidenav-tabs_container .mat-mdc-tab-header {
    padding: 0 40px 0 30px !important;
    margin: 6px -40px 0 -30px !important;
  }
  .sidenav-tabs_container .mdc-tab__text-label span,
  .sidenav-tabs_container .mdc-tab__text-label {
    font-size: 10px !important;
  }
  .mat-mdc-tab:not(.mdc-tab--stacked) {
    height: 30px !important;
  }
  .custom_table tbody td .percentage {
    font-size: 10px;
  }
  .custom_table tbody td .negative,
  .custom_table tbody td .positive {
    padding: 1px 5px 1px 5px !important;
    font-size: 10px !important;
  }
  .custom_table .material-icons {
    font-size: 13px;
  }
  .custom_table_sort-icon {
    width: 20px;
    height: 20px;
  }
  .custom_table-editButton {
    font-size: 12px !important;
    cursor: pointer;
  }
  .custom_table thead th > div {
    min-height: 16px;
  }
  .custom-button mat-icon,
  .custom-buttons_container button mat-icon {
    font-size: 14px !important;
    height: unset !important;
    width: unset !important ;
  }
  .merchandise-filters-scroll {
    zoom: 0.75;
  }
  .diagnostic-overall-show_percentage-toogle {
    font-size: 10px !important;
  }
  .merchandise-filters-down-left mat-form-field {
    width: 75px !important;
  }
  .chart-item-container .chart-item-bar {
    height: 18px !important;
  }
  .diagnostic-overall-header_container .chart-item-container {
    min-height: 44px !important;
  }
  .frq-bar-charts-holder {
    zoom: var(--zoom-val);
  }
  .diagnostic-overall-header_container {
    margin-top: 5px !important;
  }
  .custom_table .text_align-right svg {
    width: 13px;
    height: 13px;
  }
  .chart-item-container .chart-item-bar-value {
    font-size: 12px !important;
  }
  .diagnostic_overall_main_header {
    margin-bottom: 20px !important;
  }
  div div .pagetype_tag {
    padding: 1px 2px !important;
  }
  .custom_option_picker .mat-mdc-select-arrow {
    width: 12px !important;
    height: 12px !important;
    background-position: center !important;
  }
  .chart-item-container .chart-item-title {
    width: 43%;
  }
  .chart-item-container div p {
    padding: 0px 5px 0px 0px !important;
  }
  .chart-item-container .chart-item-title p,
  .chart-item-title_pagename {
    font-size: 8px !important;
  }
  .sidenav-tabs_container {
    margin-bottom: 17px !important;
    padding-bottom: 15px !important;
  }
  .custom_option_picker {
    padding: 0 4px !important;
    font-size: 10px !important;
  }
  .custom_option_picker.custom_option_picker_width120
    .mat-mdc-form-field-infix {
    width: 85px !important;
  }
  /* .diagnostic_detailed_main .custom_table-container {
    zoom: 0.75 !important;
  } */
  .custom_option_picker.custom_option_picker_width80 .mat-mdc-form-field-infix {
    width: 60px !important;
  }
  .custom_option_picker .mat-mdc-text-field-wrapper {
    padding: 0px 1px !important;
  }
  .custom_table thead th,
  .custom_table tbody td {
    padding: 6px 12px;
  }
  .seo-ai_analysis-backlinks-table_container
    .custom_table-container
    .custom_table
    tbody
    td,
  .seo_position_tracking .custom_table-container .custom_table tbody td,
  .seo-organic_keywords
    .custom_table-container
    ~ .custom_table-container
    .custom_table
    tbody
    td {
    padding: 8px 12px !important;
  }
  .custom-buttons_container button,
  div div .custom-button,
  .custom-button.btn_withoutIcon,
  .custom-buttons_container button.btn_withoutIcon {
    padding: 2px 5px !important;
    font-size: 10px !important;
    height: 20px !important;
  }
  .custom-button .button_download_icon {
    width: 11px !important;
  }
  .custom-button .mdc-button__label > span:first-child {
    top: 0 !important;
  }
  .date-controller-container {
    padding: 0px 8px !important;
  }
  .range-sel .mat-mdc-form-field-infix {
    gap: 6px !important;
    min-height: 34px !important;
    font-size: 10px !important;
  }
  .range-sel .mat-mdc-form-field-infix span {
    font-size: 10px !important;
  }
  mat-toolbar .dtholder {
    padding: 0px !important;
  }
  .range-sel .mat-mdc-form-field-infix p {
    font-size: 10px !important;
  }
  .range-sel mat-form-field > div:first-child {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .cdk-overlay-container .mat-mdc-option {
    padding: 0 7px !important;
    font-size: 11px !important;
    min-height: 28px !important;
  }
  /* .cdk-overlay-pane {
    min-width: 100px !important;
  } */
  .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
    width: 7px !important;
    height: 5px !important;
  }
  .bugPieChart-lengend-container .bugPieChart-lengend-text {
    font-size: 10px !important;
  }
  .pageBarChart-main-charts-name,
  .bug-page-item p,
  .bug_tracking-charts-container
    .bugPieChart-lengend-container
    .bugPieChart-lengend-text {
    font-size: 9px !important;
  }
  .custom_option_picker .mat-mdc-select {
    height: 18px;
  }
  .seo-measure_relevancy_main-input-container .custom_option_picker {
    padding: 2px 8px !important;
    border-radius: 10px !important;
  }
  .seo-measure_relevancy_main-input-container
    .custom_option_picker
    .mat-mdc-select {
    height: 26px;
  }
  .logout-menu .mat-mdc-menu-item {
    min-height: 34px !important;
    font-size: 13px !important;
    padding: 0 10px !important;
  }
  .custom_option_picker.custom_option_picker_width130
    .mat-mdc-form-field-infix {
    width: 106px !important;
  }
  .logout-menu .mat-mdc-menu-item .mat-icon {
    margin-right: 0px !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }
  .mat-datepicker-content .mat-calendar {
    zoom: 0.75;
  }
  .preview-image-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(185px, 1fr)) !important;
    gap: 15px !important;
  }
  .preview-screenshot-box {
    height: 135px !important;
  }
  .preview-screenshot-box img {
    min-height: 135px !important;
  }
  .custom_option_picker.custom_option_picker_width123
    .mat-mdc-form-field-infix {
    width: 98px !important;
  }
  .image-content_container {
    width: 100% !important;
    height: 85px !important;
  }
  .incentiveStatusSlider {
    position: relative;
    top: -2px;
  }
  .incentiveStatusSlider p {
    font-size: 10px !important;
    margin-left: 0 !important;
  }
  .master_preview_main-header.list-n {
    zoom: 0.75;
  }
  .preview-screenshot-box p {
    font-size: 10px !important;
  }
  /* to be tested */
  /* .custom_table-container {
    zoom: 0.75;
  } */
  .diagnostic_overall_main_header-child-header p {
    font-size: 11px !important;
  }
  .diagnostic_overall_main_header-child-value p {
    font-size: 20px !important;
  }
  .diagnostic_overall_main_header-child-value img,
  .diagnostic_overall_main_header-child-footer {
    zoom: 0.75;
  }
  .diagnostic_detailed_main .pagetype_tag {
    font-size: 10px !important;
  }
  body > .cdk-overlay-container .mat-mdc-tooltip .mdc-tooltip__surface {
    font-size: 9px !important;
    line-height: 1.2 !important;
  }
  .diagnostic-overall-header {
    gap: 10px !important;
  }
  .bug_tracking-charts-container-divider {
    margin-top: 20px !important;
  }
  .pageBarChart-main-charts-bar-value {
    font-size: 9px !important;
    padding: 3px !important;
  }
  .subtitle-h2 {
    font-size: 12px !important;
  }
  .pageBarChart-container {
    height: 200px !important;
  }
  .pageBarChart-skeleton,
  .pageBarChart-main {
    height: calc(100% - 26px) !important;
  }
  .bug-charts-container-detail .bugPiechart-container {
    margin-top: 20px !important;
    padding-top: 20px !important;
  }
  .pageBarChart-skeleton-line-number {
    font-size: 9px !important;
  }
  .bugPieChart-lengend-titles {
    font-size: 11px !important;
  }
  .bugPieChart-lengend-container {
    gap: 0 !important;
  }
  .bugPieChart-lengend-container .bugPieChart-lengend-dot {
    width: 8px !important;
    height: 8px !important;
  }
  div div .custom-button,
  .custom-buttons_container button {
    line-height: unset;
  }
  .custom_option_datepicker-container input {
    top: 0px !important;
    font-size: 11px !important;
    width: 75px !important;
  }
  .information-container .information-value {
    padding: 29.5px 24px !important;
  }
  .merchandise-overview-container {
    zoom: 0.9;
  }

  .highest-impact-container h2 {
    font-size: 13px !important;
  }
  .merchandise-overview-graph-title {
    font-size: 13px !important;
  }
  .tab_option_picker .custom_option_picker .mat-mdc-form-field-infix {
    width: 165px !important;
  }
  .seo-ai_analysis-url_item p.seo-ai_analysis-url_item-serp_title {
    font-size: 13px !important;
    margin-bottom: 3px !important;
  }
  .ai_analysis_input_button-analyse span {
    font-size: 10px !important;
  }
  .seo-ai_analysis-url_img {
    height: 25px !important;
    width: 25px !important;
  }
  .seo_position_tracking .custom_table thead th,
  .seo_position_tracking .custom_table tbody td {
    min-width: unset !important;
  }
  .seo_position_tracking .custom_table .custom-button.analyze_disabled {
    width: unset !important;
    padding: 2px 9px !important;
  }
  .seo-ai_analysis-url_item-flex {
    margin-bottom: 5px !important;
  }
  .barchart_with_description-factors {
    gap: 4px !important;
  }
  .ai_analysis-custom-barchart-comparable-factors {
    gap: 0 !important;
  }
  .ai_analysis-hr_line {
    border-bottom: 1px solid #dee3ea59 !important;
    margin: 15px 0 !important;
  }
  .seo-measure_relevancy-input_container-filter,
  .ai_analysis-filters_container .ai_analysis-filter {
    padding: 4px 6px !important;
    border-width: 1px !important;
  }
  .seo-measure_relevancy-input_container-filter p,
  .ai_analysis-filters_container .ai_analysis-filter p {
    font-size: 10px !important;
  }
  .linechart-annotation-container p,
  .linechart-container_legend {
    font-size: 10px !important;
  }
  .sidenav-tabs_container-title-container
    .tab_option_picker
    .tab_option_picker-label {
    font-size: 11px;
  }
  .custom_option_datepicker-container .mat-mdc-button-base svg {
    width: 12px !important;
    height: 12px !important;
    top: 4px !important;
    right: 6px !important;
  }
  .seo-prospect-analysis_backlinks_input-container {
    padding: 16px !important;
  }
  .seo-prospect-analysis_backlinks-urls p {
    font-size: 12px !important;
  }
  .seo-prospect-analysis_backlinks-item_icons {
    gap: 8px !important;
    font-size: 12px !important;
  }
  .seo-backlink_relevancy-content_textarea label {
    font-size: 11px !important;
  }
  .seo-backlink_relevancy-content_textarea textarea {
    font-size: 12px !important;
  }
  .custom_horizontal_option_select p span {
    font-size: 9px !important;
  }
  .seo-backlist_list .seo-backlist_list_second_header > div:first-child {
    font-size: 10px !important;
  }
  .trace-errors-piecharts-container {
    zoom: 0.75;
  }
  .ai_generated_text_container {
    zoom: 0.75;
  }
  .ai_generated_text-rate_container {
    zoom: 0.75;
  }
  .trace-errors .custom_table-container-h2 {
    margin: 10px 0 !important;
  }
  .trace-errors-info_container-item {
    padding: 12px !important;
  }
  .trace-errors .trace-errors-info_container {
    padding: 10px !important;
  }
  .trace-errors-info_container-item p {
    font-size: 12px !important;
  }
  .trace-errors-piecharts-container .trace-errors-piecharts-container_title {
    font-size: 16px !important;
  }
  .trace-errors span.mdc-button__label {
    display: flex;
    align-items: center;
    gap: 2px;
  }
  .trace-errors .trace-errors-piecharts-container_total {
    font-size: 22px !important;
  }
  .trace-errors .trace-errors-errorsVisit-item {
    padding: 7px 9px !important;
    margin: 0 5px 8px 0 !important;
  }
  .trace-errors .trace-errors-errorsVisit-item p {
    font-size: 11px !important;
  }
  .trace-errors .trace-errors-errorsVisit-item-play_icon svg {
    width: 10px !important;
    height: 10px !important;
  }
  .screen-recorder .custom_table tbody td {
    padding: 6px 14px !important;
  }
  .screen-recorder div .minWidth120 {
    min-width: 100px !important;
  }
  .screen-recorder div .minWidth150 {
    min-width: 125px !important;
  }
  .custom_table .countryFlag .fi {
    width: 14px !important;
    height: 14px !important;
  }
  .custom_table .svg-logo:not(.custom_table_sort-icon) {
    width: 14px !important;
    height: 14px !important;
  }
  .custom_table .heatmap_link mat-icon {
    font-size: 14px !important;
    top: 0 !important;
  }
  .recorder_player_tabs_firstpage {
    zoom: 0.75;
  }
  .recorder_player_events_container mat-tab-header.mat-mdc-tab-header {
    zoom: 0.75;
  }
  .recorder_player_events_container
    .mat-mdc-tab-body-content
    .ng-trigger-translateTab {
    zoom: 0.75;
  }
  .recorder_modal .recorder_modal_title {
    font-size: 16px !important;
  }
  .recorder_modal .recorder_modal_text {
    font-size: 14px !important;
  }
  .recorder_modal .recorder_modal_input_container {
    margin-bottom: 10px !important;
  }
  .recorder_modal label {
    font-size: 10px !important;
  }
  .recorder_modal .recorder_input {
    padding: 4px 8px !important;
    border-radius: 6px !important;
    font-size: 12px !important;
  }
  .recorder_modal .mat-mdc-select-value-text {
    font-size: 12px !important;
  }
  .mat-mdc-option .mat-icon {
    width: 18px !important;
    height: 18px !important;
    font-size: 18px !important;
  }
  .recorder_modal .selected_device mat-icon {
    width: 16px !important;
    height: 14px !important;
    font-size: 14px !important;
    top: 3px !important;
  }
  input[type="datetime-local" i]::-webkit-calendar-picker-indicator {
    background-size: 12px !important;
  }
  .recorder_modal .mat-mdc-select-trigger {
    position: relative;
    top: -4px;
  }
  .user-action_modal .mat-mdc-select-trigger {
    top: unset;
  }
  .custom_option_picker_with_absolute_label
    .mat-mdc-form-field
    .mdc-text-field {
    height: 22px !important;
  }
  .mdc-dialog__container .mdc-text-field {
    height: 22px !important;
  }
  .add_keyword_modal.recorder_modal .mat-mdc-select-trigger {
    top: 0;
  }
  .add_keyword_modal .custom_option_picker .mat-mdc-select {
    height: 20px !important;
    position: relative;
    top: -2px;
  }
  div
    .mdc-dialog__container
    .add_keyword_modal
    .custom_option_picker
    .mat-mdc-text-field-wrapper {
    height: 20px !important;
  }
  .recorder_modal button {
    height: 28px !important;
  }
  .recorder_modal button span {
    font-size: 12px !important;
  }
  .screen-recorder-heatmap .recorder_player_nav_goBack {
    font-size: 12px !important;
  }
  .screen-recorder-heatmap .screen-recorder-heatmap_left {
    padding: 23px 20px 20px 10px !important;
    gap: 8px !important;
  }
  .recorder_player_nav_goBack svg {
    width: 14px !important;
    height: 14px !important;
  }
  .recorder_player_playback_list-items {
    min-width: unset !important;
  }
  .recorder_player .recorder_player_playback_list-item {
    padding: 6px !important;
  }
  .recorder_player .recorder_player_playback_list-item p {
    font-size: 10px !important;
  }
  .recorder_player_playback_list-item .list_item_duration {
    width: unset !important;
    min-width: 30px !important;
  }
  .recorder_player .recorder_player_action_group_time {
    padding: 2px 4px !important;
  }
  .recorder_player_action .recorder_player_action-element,
  .recorder_player_action .recorder_player_action-type,
  .recorder_player .recorder_player_action_group_time span,
  .recorder_player .recorder_player_progressBar_time,
  .recorder_player .recorder_player_progressBar_skipInactive,
  .recorder_player .show-more-rows p {
    font-size: 10px !important;
  }
  .recorder_player_progressBar_speedTime {
    height: 24px !important;
  }
  .recorder_player_progressBar_container {
    height: 30px !important;
    margin-top: -15px !important;
  }
  .recorder_player_progressBar_skip svg,
  .recorder_player_controller_playPause svg {
    width: 16px !important;
    height: 16px !important;
  }
  .recorder_player_action-icon img {
    width: 18px !important;
    height: 18px !important;
  }
  .heatmap_main-header-title {
    font-size: 16px !important;
  }
  .heatmap-side-content_container-row p,
  .screen-recorder-heatmap .screen-recorder-heatmaps_list-add_heatmap,
  .screen-recorder-heatmap .heatmap-side-content_header h3,
  .screen-recorder-heatmap p.screen-recorder-heatmaps_list-title,
  .heatmap_main-header-title + p {
    font-size: 12px !important;
  }
  .screen-recorder-heatmaps_list {
    gap: 8px !important;
  }
  .screen-recorder-heatmaps_list-item {
    padding: 6px !important;
  }
  .screen-recorder-heatmaps_list-item p {
    font-size: 10px !important;
  }
  .screen-recorder-heatmap-search input {
    padding: 2px 6px !important;
    font-size: 12px !important;
  }
  .screen-recorder-heatmap .mat-icon {
    width: 18px !important;
    height: 18px !important;
    font-size: 18px !important;
  }
  .heatmap-side-content_header-icon svg {
    width: 14px !important;
  }
  .screen-recorder-heatmap .heatmap-maptypes_label {
    font-size: 10px !important;
  }
  .heatmap-maptypes_icon svg {
    height: 28px !important;
  }
  .seo-organic_keywords .text_align-right button {
    margin: 0 0 0 auto !important;
    min-width: 80px !important;
  }
  .seo_position_tracking
    .custom_table
    .custom-button:not(.view_ai_analysis_btn),
  .custom_table_position_status {
    width: 90px !important;
  }
  .custom_table_position_status p {
    font-size: 10px !important;
  }
  .seo_position_tracking .custom_table > thead > tr > th.minWidth20 {
    min-width: 20px !important;
  }
  .sidenav-tabs_container-title-container h3 {
    font-size: 18px !important;
  }
  .seo-prospect-analysis_backlinks_input input {
    font-size: 12px !important;
  }
  .ai_analysis_input input {
    padding: 8px !important;
    font-size: 11px !important;
  }
  .seo-ai_analysis .ai_analysis_input_button {
    padding: 8px 10px 10px 10px !important;
  }
  .information-container {
    zoom: 0.75;
  }
  .seo-backlist_list_second_header .custom_horizontal_option_select {
    min-width: 30px !important;
  }
  .custom_horizontal_option_select p {
    font-size: 10px !important;
  }
  .seo-ai_analysis-loader_v2 {
    gap: 10px !important;
  }
  .seo-ai_analysis-loader_v2-headline {
    height: 40px !important;
    margin-bottom: 10px !important;
  }
  .seo-ai_analysis-loader_v2-headline_child {
    font-size: 20px !important;
  }
  .seo-ai_analysis-loader_v2-image svg {
    width: 200px !important;
    height: 200px !important;
  }
  .seo-ai_analysis-loader_v2-bar_container {
    max-width: 380px !important;
    height: 2px !important;
  }
  .seo-ai_analysis-loader_v2-more_information {
    font-size: 10px !important;
  }
  .seo-ai_analysis-loader_v2 {
    gap: 10px !important;
  }
  .seo-ai_analysis-loader_v2-headline {
    height: 40px !important;
    margin-bottom: 10px !important;
  }
  .seo-ai_analysis-loader_v2-headline_child {
    font-size: 20px !important;
  }
  .seo-ai_analysis-loader_v2-image svg {
    width: 200px !important;
    height: 200px !important;
  }
  .seo-ai_analysis-loader_v2-bar_container {
    max-width: 380px !important;
    height: 2px !important;
  }
  .seo-ai_analysis-loader_v2-more_information {
    font-size: 10px !important;
  }
  /* .merchandise-overview-container {
    zoom: 0.75;
  } */
  .product-details-filters {
    zoom: 0.75;
  }
  .product-details-container {
    zoom: 0.75;
  }
  .highest-impact-container p span {
    font-size: 11px !important;
  }
  .highest-impact-container p {
    font-size: 11px !important;
  }
  .hsg-pageBarChart-axis h5 {
    font-weight: 500 !important;
  }
  .product-characteristics-title {
    font-weight: 500 !important;
  }
  .bar-descripton {
    margin-top: 7px !important;
    font-size: 11px !important;
  }
  .hsg-pageBarChart-main-charts-name p {
    font-size: 11px !important;
  }
  .user-behavior-title {
    font-weight: 500 !important;
  }
  .merchandise-lengend-titles p {
    font-size: 11px !important;
  }
  .merchandise-overview-graph-title {
    margin-bottom: 30px !important;
  }
  .custom-graph-bar {
    font-size: 11px !important;
  }
  .x-axis {
    left: 21% !important;
    width: 243px !important;
  }
  .merchandise-overview-bottom-container .custom_table-container {
    zoom: 1 !important;
  }
  .merchandise-image-container {
    height: 50px !important;
  }
  .merch-chart-2 {
    height: 185px !important;
  }
  .merchandise-overview-casualImpactHolder-inner {
    zoom: 0.8 !important;
    margin: 5px 0 !important;
  }
  .product-details-bottom-table-graph {
    margin-top: 20px !important;
  }
  .trace-errors .event-down-section {
    padding-bottom: 2px !important;
  }
  .trace-errors .event-upper-section {
    padding-bottom: 22px !important;
  }
  .trace-errors .trace-errors-event:first-child {
    padding-top: 10px !important;
  }
  .trace-errors .trace-errors-event a {
    font-size: 11px !important;
  }
  .trace-errors .trace-errors-event a svg {
    width: 16px !important;
  }
  .trace-errors .custom_table-container-h2 {
    margin-top: 0px !important;
  }
  .trace-errors .event-steps .event-stepsInfo p {
    font-size: 11px !important;
  }
  .trace-errors .event-steps-container p {
    font-size: 11px !important;
  }
  .trace-errors-event p {
    font-size: 11px !important;
  }
}
