.mat-mdc-form-field.bhm-option-container-1 {
  --form-field-infix-width: 180px;

  font-size: 14px;
  background: #fff;
  padding: 0;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0px 1px 1px 0px #0000001f, 0px 0px 0px 1px #3c425729,
    0px 2px 5px 0px #3c425714 !important;
  margin: 1px;
  height: 24px !important;
  min-height: 24px !important;

  .mat-mdc-form-field-flex {
    padding: 0 6px;
    border-radius: 4px;
  }

  .mdc-text-field--filled {
    background: transparent !important;
  }
  .mat-mdc-select-trigger::before {
    content: var(--label-name);
    color: #808080;
    display: inline-block;
    white-space: nowrap;
    margin-right: 6px;
  }
  .mdc-text-field {
    padding: 0;
  }
  .mdc-line-ripple,
  .mat-mdc-form-field-bottom-align {
    display: none;
  }
  .mat-mdc-form-field-infix {
    width: var(--form-field-infix-width);
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 24px !important;
    min-height: 24px !important;
  }
  .mat-mdc-select-arrow-wrapper {
    svg {
      fill: #000;
    }
  }
  .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
  }
  .mdc-text-field--disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
/* style for select options that are appended on the body overlay */
.mat-mdc-option.bhm-option-select-option-1 {
  padding-right: 10px;
  padding-left: 10px;

  .mdc-list-item__primary-text {
    font-size: 14px;
  }
  .mat-pseudo-checkbox {
    transform: scale(0.8);
  }

  &.online-status-check {
    &:not(.mdc-list-item--selected)::after {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      background: #5469d4;
    }

    .mat-pseudo-checkbox::after {
      color: #5469d4 !important;
    }
  }
}

.dark-theme {
  .mat-mdc-form-field.bhm-option-container-1 {
    background: #303030;
    box-shadow: 0px 1px 1px 0px #0000001f, 0px 0px 0px 1px #ffffff29,
      0px 2px 5px 0px #ffffff14 !important;

    .mat-mdc-select-arrow-wrapper {
      svg {
        fill: #fff;
      }
    }

    .mat-mdc-select-trigger::before {
      color: #fff;
      opacity: 0.7;
    }
  }
  .mat-mdc-option.bhm-option-select-option-1 {
    .mat-pseudo-checkbox::after {
      color: var(--text-main-color) !important;
    }
    &.online-status-check {
      .mat-pseudo-checkbox::after {
        color: #5469d4 !important;
      }
    }
  }
}

@media only screen and (max-width: 1450px) {
  .mat-mdc-form-field.bhm-option-container-1 {
    font-size: 10px;
    height: 20px !important;
    min-height: 20px !important;

    .mat-mdc-form-field-infix {
      height: 20px !important;
      min-height: 20px !important;
    }
    .mat-mdc-select-arrow-wrapper {
      height: 20px;

      svg {
        width: 20px;
      }
    }

    .mat-mdc-form-field-infix {
      width: calc(var(--form-field-infix-width) - 20px);
    }
  }
  .mat-mdc-option.bhm-option-select-option-1 {
    .mdc-list-item__primary-text {
      font-size: 12px;
    }
  }
}
